import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import colors from "@styles/variables/homewarrantyVariables.module.scss";
import SaveMoney from "./icons/saveMoney";
import SaveTime from "./icons/saveTime";
import BonusBenefits from "./icons/bonusBenefits";
import FeatureList from "@components/shared/featureList";

const benefitsList = [
    {
        title: "Save Money",
        description:
            "A home warranty can help homeowners save money by covering repair or replacement costs, offering discounts on regular maintenance services, and providing coverage for pre-existing conditions.",
        icon: <SaveMoney />,
    },
    {
        title: "Save Time",
        description:
            "With a home warranty, homeowners can easily file a claim to have a technician dispatched automatically, usually within a day or two, avoiding wasted time on finding a qualified technician or scheduling appointments on their own.",
        icon: <SaveTime />,
    },
    {
        title: "Bonus Benefits",
        description:
            "Home warranty companies may offer extra perks to their customers. These can include exclusive discounts on popular products, home security items, and even restaurants.",
        icon: <BonusBenefits />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <FeatureList
            featureList={benefitsList}
            mainTitle="The Benefits of a Home Warranty"
            classes={{
                mainTitleClasses: ` font-bold text-2xl text-center   lg:text-4xl  `,
                innerSectionClasses:
                    "lg:flex flex-wrap justify-center gap-[18px] lg:mt-[60px] ",
                oneBoxClasses: ` sm:w-[347px] sm:h-[454px] ${styles.item} pt-[51px] pb-[68px] px-[27px] mx-auto lg:mx-0 mt-10 lg:mt-0`,
                iconClasses: " ",
                titleClasses: "font-bold text-lg lg:text-2xl mt-[20px]",
                descriptionClasses:
                    "font-light lg:text-lg  text-base   lg:leading-7 mt-[10px]",
            }}
            colors={{
                mainTitleColor: colors.mainColor,
                titleColor: "#fff",
                descriptionColor: "#fff",
            }}
        />
    );
}
