import React from "react";

function SaveMoney() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="51"
            fill="none"
            viewBox="0 0 45 51"
        >
            <path
                fill="#fff"
                d="M22.89 27.587a.792.792 0 00-.285-.01 1.888 1.888 0 01-1.67-1.869.83.83 0 00-1.66 0 3.543 3.543 0 002.612 3.416v.829a.83.83 0 001.66 0v-.779a3.545 3.545 0 002.56-2.132 3.535 3.535 0 00-.344-3.31 3.548 3.548 0 00-2.942-1.564 1.888 1.888 0 01-1.886-1.883 1.882 1.882 0 011.886-1.882 1.881 1.881 0 011.886 1.882.83.83 0 001.66 0 3.552 3.552 0 00-2.82-3.466v-.755a.83.83 0 00-1.66 0v.806a3.544 3.544 0 00-2.402 2.215 3.535 3.535 0 00.434 3.236 3.549 3.549 0 002.902 1.505 1.884 1.884 0 011.655 2.782c-.319.583-.921.955-1.586.98z"
            ></path>
            <path
                fill="#fff"
                d="M10.778 23.06c0 3.19 1.27 6.248 3.528 8.502a12.052 12.052 0 008.516 3.522c3.194 0 6.257-1.267 8.515-3.522a12.012 12.012 0 000-17.003 12.052 12.052 0 00-17.027.003 12.027 12.027 0 00-3.532 8.498zm12.043-10.364c2.754 0 5.395 1.091 7.342 3.035a10.358 10.358 0 010 14.66 10.392 10.392 0 01-14.683 0 10.358 10.358 0 01-3.04-7.33 10.367 10.367 0 013.043-7.326 10.4 10.4 0 017.338-3.04z"
            ></path>
            <path
                fill="#fff"
                d="M38.96 37.129l-6.505 3.069a5.295 5.295 0 00-4.517-2.783l-6.006-.165a6.358 6.358 0 01-2.73-.703l-.61-.317a10.795 10.795 0 00-9.986.01l.038-1.381a.829.829 0 00-.807-.852l-6.586-.181a.83.83 0 00-.853.806L0 49.06a.825.825 0 00.807.851l6.586.181h.023a.83.83 0 00.83-.806l.02-.69 1.71-.915a2.942 2.942 0 012.191-.24l10.217 2.863c.018.006.036.01.054.014a11.098 11.098 0 006.874-.777.807.807 0 00.103-.057L44.23 39.92a.828.828 0 00.272-1.104 4.201 4.201 0 00-5.54-1.685v-.001zM1.684 48.277l.352-12.77 4.926.135-.352 12.77-4.926-.135zm26.88-.217a9.43 9.43 0 01-5.758.642l-10.19-2.855a4.6 4.6 0 00-3.423.373l-.874.468.234-8.491a9.14 9.14 0 019.274-.494l.612.316a8.024 8.024 0 003.448.889l6.006.165a3.645 3.645 0 013.462 2.898l-8.947-.247a.832.832 0 00-.853.806.83.83 0 00.808.852l9.873.271h.023a.83.83 0 00.83-.806c.01-.36-.017-.721-.08-1.076l6.672-3.147.02-.01a2.54 2.54 0 012.87.401L28.563 48.06zM23.656 8.706V.83a.83.83 0 00-1.66 0v7.877a.83.83 0 001.66 0zM29.883 8.706V4.975a.83.83 0 00-1.661 0v3.731a.83.83 0 001.66 0zM17.424 8.706V4.975a.83.83 0 00-1.661 0v3.731a.83.83 0 001.66 0z"
            ></path>
        </svg>
    );
}

export default SaveMoney;
