import React from "react";

function SaveTime() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="51"
            fill="none"
            viewBox="0 0 56 51"
        >
            <path
                fill="#fff"
                d="M54.146 32.339a3.554 3.554 0 00-3.436-.2l-8.8 4.061c-.009-1.987-1.636-3.594-3.626-3.594h-6.05c-3.054-2.66-6.862-4.285-10.245-4.371a202.44 202.44 0 00-7.918-.017v-1.573c0-1.348-1.1-2.445-2.44-2.445H2.449A2.45 2.45 0 000 26.645v18.238a2.45 2.45 0 002.449 2.444h9.173c1.35 0 2.44-1.097 2.44-2.444v-.96c3.28 1.884 11.959 6.627 16.07 6.627.086 0 .18 0 .267-.009 7.209-.406 22.551-11.326 23.633-12.103a3.544 3.544 0 001.774-3.076 3.51 3.51 0 00-1.66-3.024zM12.383 44.874a.76.76 0 01-.753.76H2.45a.762.762 0 01-.761-.76V26.636c0-.415.337-.76.761-.76h9.173c.415 0 .753.337.753.76v18.237h.008zm40.794-7.897c-.026.018-.06.035-.087.052-.155.112-15.949 11.43-22.776 11.819-3.444.181-12.522-4.692-16.243-6.877V29.9c1.411-.025 4.474-.06 7.875.018 3.08.077 6.594 1.633 9.398 4.155a.846.846 0 00.562.216h6.36c1.065 0 1.94.864 1.94 1.927 0 .173-.027.346-.07.51l-.026.078a2.12 2.12 0 01-.207.449c-.01.017-.018.035-.026.043-.346.51-.935.847-1.601.847H25.979a.849.849 0 00-.848.846c0 .467.38.847.848.847h12.297c1.177 0 2.207-.57 2.873-1.434l10.28-4.734a1.85 1.85 0 011.809.104c.554.345.883.941.883 1.59a1.827 1.827 0 01-.944 1.614z"
            ></path>
            <path
                fill="#fff"
                d="M8.324 38.636a2.537 2.537 0 00-2.535 2.531c0 1.4 1.142 2.532 2.535 2.532a2.542 2.542 0 002.536-2.532c.008-1.39-1.134-2.53-2.536-2.53zm0 3.378a.849.849 0 01-.848-.847.849.849 0 011.697 0c0 .467-.381.847-.849.847zM36.7 30.498c8.42 0 15.274-6.843 15.274-15.25C51.974 6.844 45.121 0 36.701 0S21.427 6.843 21.427 15.249 28.28 30.498 36.7 30.498zm0-28.804c7.486 0 13.578 6.083 13.578 13.556 0 7.473-6.092 13.555-13.577 13.555-7.486 0-13.578-6.082-13.578-13.555S29.215 1.695 36.7 1.695z"
            ></path>
            <path
                fill="#fff"
                d="M36.7 6.109c.466 0 .847-.38.847-.847V4.079a.849.849 0 00-1.696 0V5.27c.009.466.38.838.848.838zM36.699 24.381a.849.849 0 00-.848.847v1.192a.849.849 0 001.696 0v-1.192a.849.849 0 00-.848-.847zM29.032 8.787c.165.164.381.25.597.25a.847.847 0 00.598-1.442l-.84-.838a.844.844 0 00-1.194 1.192l.84.838zM44.023 23.75c.164.165.38.252.597.252a.847.847 0 00.597-1.444l-.84-.838a.844.844 0 00-1.194 1.192l.84.839zM27.555 15.249a.849.849 0 00-.848-.847h-1.195a.849.849 0 00-.848.847c0 .466.38.847.848.847h1.195c.467 0 .848-.38.848-.847zM46.704 16.096h1.194c.467 0 .848-.38.848-.847a.849.849 0 00-.848-.847h-1.194a.849.849 0 00-.849.847.85.85 0 00.849.847zM29.041 21.711l-.848.847a.847.847 0 00.597 1.443.847.847 0 00.597-.25l.84-.847a.844.844 0 000-1.193.836.836 0 00-1.186 0zM43.78 9.028a.847.847 0 00.597-.25l.849-.838a.844.844 0 00-1.195-1.193l-.848.847a.844.844 0 000 1.193c.165.164.38.241.597.241zM36.7 17.997c.389 0 .752-.095 1.09-.25l1.393 1.39c.164.165.38.25.597.25a.847.847 0 00.597-1.442l-1.393-1.4c.155-.328.25-.699.25-1.088 0-1.097-.709-2.03-1.687-2.376v-4.89a.849.849 0 00-1.696 0v4.89a2.516 2.516 0 00-1.688 2.376 2.54 2.54 0 002.536 2.54zm0-3.378c.467 0 .847.38.847.847a.849.849 0 01-1.696 0c0-.476.381-.847.848-.847z"
            ></path>
        </svg>
    );
}

export default SaveTime;
